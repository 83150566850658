import React, {Component} from 'react';
import {AutoComplete, Input, Row, Col} from 'antd';
import {connect} from "react-redux";
import {bindActionCreators} from "redux";
import {getCities} from '../../redux/action/cityActions'
import {getScoreCitySelected, getScoreActivitySelected} from '../../redux/action/offerActions'
import {resetLoading} from '../../redux/action/activityActions'
import {getActivitiesToInputsValues} from '../../redux/action/activityActions'
// eslint-disable-next-line
import style from './searchBar.css'
import Loading from '../loading/loading'


class SearchBar extends Component {

    componentDidMount() {
        this.props.getActivitiesToInputsValues()
    }

    render() {
        return (
            <div className="search-bar-content">
                <Row justify="center" align="middle">
                    <Col xs={24} sm={24} md={24} lg={10} className="input-autocomplete-content">
                        <AutoComplete
                            className="input-autocomplete"
                            style={{width: 325}}
                            dropdownClassName="certain-category-search-dropdown"
                            options={this.props.citiesData}
                            onChange={this.props.getCities}
                            placeholder="Votre ville"
                            onSelect={this.props.getScoreCitySelected}
                        >
                            <Input.Search size="large" loading={this.props.citiesIsSubmit}/>
                        </AutoComplete>
                    </Col>
                    <Col xs={24} sm={24} md={24} lg={4}>
                        <Loading/>
                    </Col>
                    <Col xs={24} sm={24} md={24} lg={10}>
                        <AutoComplete
                            className="input-autocomplete"
                            style={{width: 325}}
                            options={this.props.activitiesInputData}
                            placeholder="Votre domaine d'activité"
                            filterOption={(inputValue, option) =>
                                option.value.toUpperCase().indexOf(inputValue.toUpperCase()) !== -1
                            }
                            onSelect={this.props.getScoreActivitySelected}
                        >
                            <Input.Search size="large"/>
                        </AutoComplete>
                    </Col>
                </Row>
            </div>
        );
    }
}

SearchBar.propTypes = {};

function mapStateToProps(state) {
    return {
        citiesData: state.city.data,
        citiesIsSubmit: state.city.isSubmit,
        activitiesInputData: state.activity.data
    }
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators({
        getCities,
        getScoreCitySelected,
        getActivitiesToInputsValues,
        getScoreActivitySelected,
        resetLoading
    }, dispatch)
}

export default connect(mapStateToProps, mapDispatchToProps)(SearchBar);