import initialState from './initialState';
import {offers} from '../constants';

const offersReducer = (state = initialState.offers, action, payload) => {
    switch (action.type) {
        case offers.OFFERS_WITHOUT_ENGAGEMENT:
            return {
                ...state,
                withOutEngagement: action.payload
            };
        case offers.CITY_SCORE:
            return {
                ...state,
                score: {
                    ...state.score,
                    city: action.payload
                }
            };
        case offers.ACTIVITY_SCORE:
            return {
                ...state,
                score: {
                    ...state.score,
                    activity: action.payload
                }
            };
        case offers.LOADING:
            return {
                ...state,
                loading: state.loading + action.payload
            };
        case offers.RESET_LOADING:
            return {
                ...state,
                loading: 0
            };
        default:
            return state;
    }
};

export default offersReducer