import React from 'react';
import './App.css';
import 'antd/dist/antd.css';
import './components/searchBar/searchBar';
import SearchBar from "./components/searchBar/searchBar";
import imageSea from './assets/images/sea.png';
import imageVistalid from './assets/images/logo-noir-vistalid.png';
import {Layout, Row, Col} from 'antd';
import Offers from "./components/offers/offers";

const {Content, Footer} = Layout;

function App() {
    return (
        <div className="App">
            <Layout>
                <Content style={{padding: '0 50px'}} className="site-layout-background">
                    <Row className="logo-vistalid-content">
                        <img src={imageVistalid} className="logo-vistalid" alt="logo-vistalid"/>
                    </Row>
                    <Row className="header-content">
                        <Col sm={24} md={10} className="title-content">
                            <h1>Plateforme SEA</h1>
                            <span>Nous définissons ensemble vos objectifs et fixons le cap de votre stratégie digitale. Nous réalisons alors une étude approfondie du marché, de la concurrence afin de vous proposer un plan d’action sur-mesure, adapté à vos contraintes humaines et budgétaires.</span>
                        </Col>
                        <Col sm={24} md={14} className="brand-content">
                            <img src={imageSea} className="logo-sea" alt="sea-vistalid"/>
                        </Col>
                    </Row>
                    <SearchBar/>
                    <Offers/>
                </Content>
                <Footer style={{textAlign: 'center'}} className="footer">Developed with ❤️ by Vistalid - © 2021</Footer>
            </Layout>
        </div>
    );
}

export default App;
