import initialState from './initialState';
import {activity} from '../constants';

const activityReducer = (state = initialState.activity, action) => {
    switch (action.type) {
        case activity.GET_ACTIVITIES_TO_INPUT_VALUE:
            return {
                ...state,
                data: action.payload
            };
        default:
            return state;
    }
};

export default activityReducer