export const cities = [
    {
        name: 'Lesquin',
        code: 59,
        score: 2
    },
    {
        name: 'Noyelles-lès-Seclin',
        code: 59,
        score: 2
    },
    {
        name: 'Pérenchies',
        code: 59,
        score: 2
    },
    {
        name: 'Péronne-en-Mélantois',
        code: 59,
        score: 2
    },
    {
        name: 'Prémesques',
        code: 59,
        score: 2
    },
    {
        name: 'Provin',
        code: 59,
        score: 2
    },
    {
        name: 'Quesnoy-sur-Deûle',
        code: 59,
        score: 2
    },
    {
        name: 'Radinghem-en-Weppes',
        code: 59,
        score: 2
    },
    {
        name: 'Ronchin',
        code: 59,
        score: 2
    },
    {
        name: 'Roncq',
        code: 59,
        score: 2
    },
    {
        name: 'Neuville-en-Ferrain',
        code: 59,
        score: 2
    },
    {
        name: 'Mouvaux ',
        code: 59,
        score: 2
    },
    {
        name: 'Mons-en-Baroeul',
        code: 59,
        score: 2
    },
    {
        name: 'Lezennes',
        code: 59,
        score: 2
    },
    {
        name: 'Lille',
        code: 59,
        score: 2
    },
    {
        name: 'Linselles',
        code: 59,
        score: 2
    },
    {
        name: 'Lompret',
        code: 59,
        score: 2
    },
    {
        name: 'Loos',
        code: 59,
        score: 2
    },
    {
        name: 'Lys-lez-Lannoy ',
        code: 59,
        score: 2
    },
    {
        name: 'Marcq-en-Baroeul',
        code: 59,
        score: 2
    },
    {
        name: 'Marquette-lez-Lille',
        code: 59,
        score: 2
    },
    {
        name: 'Marquillies',
        code: 59,
        score: 2
    },
    {
        name: 'Roubaix',
        code: 59,
        score: 2
    },
    {
        name: 'Sailly-lez-Lannoy',
        code: 59,
        score: 2
    },
    {
        name: 'Sainghin-en-Mélantois',
        code: 59,
        score: 2
    },
    {
        name: 'Villeneuve-d\'Ascq',
        code: 59,
        score: 2
    },
    {
        name: 'Wambrechies',
        code: 59,
        score: 2
    },
    {
        name: 'Warneton',
        code: 59,
        score: 2
    },
    {
        name: 'Wasquehal',
        code: 59,
        score: 2
    },
    {
        name: 'Wattignies',
        code: 59,
        score: 2
    },
    {
        name: 'Wattrelos',
        code: 59,
        score: 2
    },
    {
        name: 'Wavrin',
        code: 59,
        score: 2
    },
    {
        name: 'Wervicq-Sud',
        code: 59,
        score: 2
    },
    {
        name: 'Wicres',
        code: 59,
        score: 2
    },
    {
        name: 'Verlinghem',
        code: 59,
        score: 2
    },
    {
        name: 'Vendeville',
        code: 59,
        score: 2
    },
    {
        name: 'Tressin',
        code: 59,
        score: 2
    },
    {
        name: 'Sainghin-en-Weppes',
        code: 59,
        score: 2
    },
    {
        name: 'Saint-André-lez-Lille',
        code: 59,
        score: 2
    },
    {
        name: 'Salomé',
        code: 59,
        score: 2
    },
    {
        name: 'Santes',
        code: 59,
        score: 2
    },
    {
        name: 'Seclin',
        code: 59,
        score: 2
    },
    {
        name: 'Sequedin',
        code: 59,
        score: 2
    },
    {
        name: 'Templemars',
        code: 59,
        score: 2
    },
    {
        name: 'Toufflers',
        code: 59,
        score: 2
    },
    {
        name: 'Tourcoing',
        code: 59,
        score: 2
    },
    {
        name: 'Willems',
        code: 59,
        score: 2
    },
    {
        name: 'Le Maisnil',
        code: 59,
        score: 2
    },
    {
        name: 'Allennes-les-Marais',
        code: 59,
        score: 2
    },
    {
        name: 'Carnin',
        code: 59,
        score: 2
    },
    {
        name: 'Chéreng',
        code: 59,
        score: 2
    },
    {
        name: 'Comines',
        code: 59,
        score: 2
    },
    {
        name: 'Croix',
        code: 59,
        score: 2
    },
    {
        name: 'Deûlémont',
        code: 59,
        score: 2
    },
    {
        name: 'Don',
        code: 59,
        score: 2
    },
    {
        name: 'Emmerin',
        code: 59,
        score: 2
    },
    {
        name: 'Englos',
        code: 59,
        score: 2
    },
    {
        name: 'Ennetières-en-Weppes',
        code: 59,
        score: 2
    },
    {
        name: 'Capinghem',
        code: 59,
        score: 2
    },
    {
        name: 'Bouvines',
        code: 59,
        score: 2
    },
    {
        name: 'Bousbecque',
        code: 59,
        score: 2
    },
    {
        name: 'Annoeullin',
        code: 59,
        score: 2
    },
    {
        name: 'Anstaing',
        code: 59,
        score: 2
    },
    {
        name: 'Armentières',
        code: 59,
        score: 2
    },
    {
        name: 'Aubers',
        code: 59,
        score: 2
    },
    {
        name: 'Baisieux',
        code: 59,
        score: 2
    },
    {
        name: 'Bauvin',
        code: 59,
        score: 2
    },
    {
        name: 'Beaucamps-Ligny',
        code: 59,
        score: 2
    },
    {
        name: 'Bois-Grenier',
        code: 59,
        score: 2
    },
    {
        name: 'Bondues',
        code: 59,
        score: 2
    },
    {
        name: 'Erquinghem-le-Sec',
        code: 59,
        score: 2
    },
    {
        name: 'Erquinghem-Lys',
        code: 59,
        score: 2
    },
    {
        name: 'Escobecques',
        code: 59,
        score: 2
    },
    {
        name: 'Herlies',
        code: 59,
        score: 2
    },
    {
        name: 'Houplin-Ancoisne',
        code: 59,
        score: 2
    },
    {
        name: 'Houplines',
        code: 59,
        score: 2
    },
    {
        name: 'Illies',
        code: 59,
        score: 2
    },
    {
        name: 'La Bassée',
        code: 59,
        score: 2
    },
    {
        name: 'La Chapelle-d\'Armentières',
        code: 59,
        score: 2
    },
    {
        name: 'La Madeleine',
        code: 59,
        score: 2
    },
    {
        name: 'Lambersart',
        code: 59,
        score: 2
    },
    {
        name: 'Lannoy',
        code: 59,
        score: 2
    },
    {
        name: 'Hem',
        code: 59,
        score: 2
    },
    {
        name: 'Haubourdin',
        code: 59,
        score: 2
    },
    {
        name: 'Hantay',
        code: 59,
        score: 2
    },
    {
        name: 'Faches-Thumesnil',
        code: 59,
        score: 2
    },
    {
        name: 'Forest-sur-Marque',
        code: 59,
        score: 2
    },
    {
        name: 'Fournes-en-Weppes',
        code: 59,
        score: 2
    },
    {
        name: 'Frelinghien',
        code: 59,
        score: 2
    },
    {
        name: 'Fretin',
        code: 59,
        score: 2
    },
    {
        name: 'Fromelles',
        code: 59,
        score: 2
    },
    {
        name: 'Gruson',
        code: 59,
        score: 2
    },
    {
        name: 'Hallennes-lez-Haubourdin',
        code: 59,
        score: 2
    },
    {
        name: 'Halluin',
        code: 59,
        score: 2
    },
    {
        name: 'Leers',
        code: 59,
        score: 2
    },
    {
        name: 'Calais',
        code: 62,
        score: 2
    },
    {
        name: 'Arras',
        code: 62,
        score: 2
    },
    {
        name: 'Boulogne-Sur-Mer,',
        code: 62,
        score: 2
    },
    {
        name: 'Lens',
        code: 62,
        score: 2
    },
    {
        name: 'Liévin',
        code: 62,
        score: 2
    },
    {
        name: 'Hénin-Beaumont',
        code: 62,
        score: 2
    },
    {
        name: 'Le Havre',
        code: 76,
        score: 2
    },
    {
        name: 'Rouen',
        code: 76,
        score: 2
    },
    {
        name: 'Beauvais',
        code: 60,
        score: 2
    },
    {
        name: 'Compiègne',
        code: 60,
        score: 2
    },
    {
        name: 'Creil',
        code: 60,
        score: 2
    },
    {
        name: 'Nogent-Sur-Oise',
        code: 60,
        score: 2
    },
    {
        name: 'Senlis',
        code: 60,
        score: 2
    },
    {
        name: 'Reims',
        code: 51,
        score: 2
    },
    {
        name: 'Châlons-En-Champagne',
        code: 51,
        score: 2
    },
    {
        name: 'Epernay',
        code: 51,
        score: 2
    },
    {
        name: 'Vitry-Le-François',
        code: 51,
        score: 2
    },
    {
        name: 'Amiens',
        code: 80,
        score: 2
    },
    {
        name: 'Abbeville',
        code: 80,
        score: 2
    },
    {
        name: 'Albert',
        code: 80,
        score: 2
    },
    {
        name: 'Saint-Quentin',
        code: 2,
        score: 2
    },
    {
        name: 'Soissons',
        code: 2,
        score: 2
    },
    {
        name: 'Laon',
        code: 2,
        score: 2
    },
    {
        name: 'Château-Thierry',
        code: 2,
        score: 2
    },
    {
        name: 'Charleville-Mézières',
        code: 2,
        score: 2
    },
    {
        name: 'Sedan',
        code: 2,
        score: 2
    },
    {
        name: 'Villeurbanne',
        code: 69,
        score: 2
    },
    {
        name: 'Saint-Cyr-au-Mont-d\'Or',
        code: 69,
        score: 2
    },
    {
        name: 'Rochetaillée-sur-Saône',
        code: 69,
        score: 2
    },
    {
        name: 'Rillieux-la-Pape',
        code: 69,
        score: 2
    },
    {
        name: 'Quincieux',
        code: 69,
        score: 2
    },
    {
        name: 'Poleymieux-au-Mont-d\'Or',
        code: 69,
        score: 2
    },
    {
        name: 'Pierre-Bénite',
        code: 69,
        score: 2
    },
    {
        name: 'Oullins',
        code: 69,
        score: 2
    },
    {
        name: 'Neuville-sur-Saône ',
        code: 69,
        score: 2
    },
    {
        name: 'Montanay',
        code: 69,
        score: 2
    },
    {
        name: 'Mions',
        code: 69,
        score: 2
    },
    {
        name: 'Meyzieu',
        code: 69,
        score: 2
    },
    {
        name: 'Marcy-l\'Étoile',
        code: 69,
        score: 2
    },
    {
        name: 'Saint-Didier-au-Mont-d\'Or',
        code: 69,
        score: 2
    },
    {
        name: 'Sainte-Foy-lès-Lyon',
        code: 69,
        score: 2
    },
    {
        name: 'Saint-Fons',
        code: 69,
        score: 2
    },
    {
        name: 'Vernaison',
        code: 69,
        score: 2
    },
    {
        name: 'Vénissieux',
        code: 69,
        score: 2
    },
    {
        name: 'Vaulx-en-Velin',
        code: 69,
        score: 2
    },
    {
        name: 'Tassin-la-Demi-Lune',
        code: 69,
        score: 2
    },
    {
        name: 'Solaize',
        code: 69,
        score: 2
    },
    {
        name: 'Sathonay-Village',
        code: 69,
        score: 2
    },
    {
        name: 'Sathonay-Camp',
        code: 69,
        score: 2
    },
    {
        name: 'Saint-Romain-au-Mont-d\'Or',
        code: 69,
        score: 2
    },
    {
        name: 'Saint-Priest',
        code: 69,
        score: 2
    },
    {
        name: 'Saint-Germain-au-Mont-d\'Or',
        code: 69,
        score: 2
    },
    {
        name: 'Saint-Genis-les-Ollières',
        code: 69,
        score: 2
    },
    {
        name: 'Saint-Genis-Laval',
        code: 69,
        score: 2
    },
    {
        name: 'Lyon',
        code: 69,
        score: 2
    },
    {
        name: 'Lissieu',
        code: 69,
        score: 2
    },
    {
        name: 'Limonest',
        code: 69,
        score: 2
    },
    {
        name: 'Curis-au-Mont-d\'Or',
        code: 69,
        score: 2
    },
    {
        name: 'Craponne',
        code: 69,
        score: 2
    },
    {
        name: 'Couzon-au-Mont-d\'Or',
        code: 69,
        score: 2
    },
    {
        name: 'Corbas',
        code: 69,
        score: 2
    },
    {
        name: 'Collonges-au-Mont-d\'Or',
        code: 69,
        score: 2
    },
    {
        name: 'Chassieu',
        code: 69,
        score: 2
    },
    {
        name: 'Charly',
        code: 69,
        score: 2
    },
    {
        name: 'Charbonnières-les-Bains',
        code: 69,
        score: 2
    },
    {
        name: 'Champagne-au-Mont-d\'Or',
        code: 69,
        score: 2
    },
    {
        name: 'Caluire-et-Cuire',
        code: 69,
        score: 2
    },
    {
        name: 'Cailloux-sur-Fontaines',
        code: 69,
        score: 2
    },
    {
        name: 'Bron',
        code: 69,
        score: 2
    },
    {
        name: 'Dardilly',
        code: 69,
        score: 2
    },
    {
        name: 'Décines-Charpieu',
        code: 69,
        score: 2
    },
    {
        name: 'Écully',
        code: 69,
        score: 2
    },
    {
        name: 'La Tour-de-Salvagny',
        code: 69,
        score: 2
    },
    {
        name: 'La Mulatière',
        code: 69,
        score: 2
    },
    {
        name: 'Jonage',
        code: 69,
        score: 2
    },
    {
        name: 'Irigny',
        code: 69,
        score: 2
    },
    {
        name: 'Grigny ',
        code: 69,
        score: 2
    },
    {
        name: 'Givors',
        code: 69,
        score: 2
    },
    {
        name: 'Genay',
        code: 69,
        score: 2
    },
    {
        name: 'Francheville',
        code: 69,
        score: 2
    },
    {
        name: 'Fontaines-sur-Saône',
        code: 69,
        score: 2
    },
    {
        name: 'Fontaines-Saint-Martin',
        code: 69,
        score: 2
    },
    {
        name: 'Fleurieu-sur-Saône',
        code: 69,
        score: 2
    },
    {
        name: 'Feyzin',
        code: 69,
        score: 2
    },
    {
        name: 'Albigny-sur-Saône',
        code: 69,
        score: 2
    },
    {
        name: 'Grenoble',
        code: 38,
        score: 2
    },
    {
        name: 'Saint-Martin-d\'Hères',
        code: 38,
        score: 2
    },
    {
        name: 'Saint-Etienne',
        code: 42,
        score: 2
    },
    {
        name: 'Saint-Chamond',
        code: 42,
        score: 2
    },
    {
        name: 'Clermont-Ferrand',
        code: 63,
        score: 2
    },
    {
        name: 'Cournon-d\'Auvergne',
        code: 63,
        score: 2
    },
    {
        name: 'Valence',
        code: 63,
        score: 2
    },
    {
        name: 'Montélimar',
        code: 63,
        score: 2
    },
    {
        name: 'Romans-Sur-Isère',
        code: 63,
        score: 2
    },
    {
        name: 'Bourg-En-Bresse',
        code: 1,
        score: 2
    },
    {
        name: 'Oyonnax',
        code: 1,
        score: 2
    },
    {
        name: 'Chalon-Sur-Saone',
        code: 71,
        score: 2
    },
    {
        name: 'Mâcon',
        code: 71,
        score: 2
    },
    {
        name: 'Annonay',
        code: 7,
        score: 2
    },
    {
        name: 'Montluçon',
        code: 3,
        score: 2
    },
    {
        name: 'Dole',
        code: 39,
        score: 2
    },
    {
        name: 'Chambéry',
        code: 73,
        score: 2
    },
    {
        name: 'Aix-Les-Bains',
        code: 73,
        score: 2
    },
    {
        name: 'Annecy',
        code: 74,
        score: 2
    },
    {
        name: 'Annemasse',
        code: 74,
        score: 2
    },
    {
        name: 'Thonon-Les-Bains',
        code: 74,
        score: 2
    },
    {
        name: 'Le-Puy-En-Velay',
        code: 43,
        score: 2
    },
    {
        name: 'Ambarès-et-Lagrave',
        code: 33,
        score: 2
    },
    {
        name: 'Le Haillan',
        code: 33,
        score: 2
    },
    {
        name: 'Le Taillan-Médoc',
        code: 33,
        score: 2
    },
    {
        name: 'Lormont',
        code: 33,
        score: 2
    },
    {
        name: 'Martignas-sur-Jalle',
        code: 33,
        score: 2
    },
    {
        name: 'Mérignac',
        code: 33,
        score: 2
    },
    {
        name: 'Parempuyre',
        code: 33,
        score: 2
    },
    {
        name: 'Pessac',
        code: 33,
        score: 2
    },
    {
        name: 'Saint-Aubin-de-Médoc',
        code: 33,
        score: 2
    },
    {
        name: 'Saint-Louis-de-Montferrand',
        code: 33,
        score: 2
    },
    {
        name: 'Saint-Médard-en-Jalles',
        code: 33,
        score: 2
    },
    {
        name: 'Saint-Vincent-de-Paul ',
        code: 33,
        score: 2
    },
    {
        name: 'Talence',
        code: 33,
        score: 2
    },
    {
        name: 'Le Bouscat',
        code: 33,
        score: 2
    },
    {
        name: 'Gradignan',
        code: 33,
        score: 2
    },
    {
        name: 'Ambès',
        code: 33,
        score: 2
    },
    {
        name: 'Artigues-près-Bordeaux',
        code: 33,
        score: 2
    },
    {
        name: 'Bassens',
        code: 33,
        score: 2
    },
    {
        name: 'Bègles',
        code: 33,
        score: 2
    },
    {
        name: 'Blanquefort ',
        code: 33,
        score: 2
    },
    {
        name: 'Bordeaux',
        code: 33,
        score: 2
    },
    {
        name: 'Bouliac',
        code: 33,
        score: 2
    },
    {
        name: 'Bruges',
        code: 33,
        score: 2
    },
    {
        name: 'Carbon-Blanc ',
        code: 33,
        score: 2
    },
    {
        name: 'Cenon',
        code: 33,
        score: 2
    },
    {
        name: 'Eysines',
        code: 33,
        score: 2
    },
    {
        name: 'Floirac',
        code: 33,
        score: 2
    },
    {
        name: 'Villenave-d\'Ornon',
        code: 33,
        score: 2
    },
    {
        name: 'Toulouse',
        code: 31,
        score: 2
    },
    {
        name: 'Colomiers',
        code: 31,
        score: 2
    },
    {
        name: 'Tournefeuille',
        code: 31,
        score: 2
    },
    {
        name: 'Blagnac',
        code: 31,
        score: 2
    },
    {
        name: 'Muret',
        code: 31,
        score: 2
    },
    {
        name: 'Pau',
        code: 64,
        score: 2
    },
    {
        name: 'Bayonne',
        code: 64,
        score: 2
    },
    {
        name: 'Anglet',
        code: 64,
        score: 2
    },
    {
        name: 'Biarritz',
        code: 64,
        score: 2
    },
    {
        name: 'La Rochelle',
        code: 17,
        score: 2
    },
    {
        name: 'Saintes',
        code: 17,
        score: 2
    },
    {
        name: 'Rochefort',
        code: 17,
        score: 2
    },
    {
        name: 'Périgueux',
        code: 24,
        score: 2
    },
    {
        name: 'Bergerac',
        code: 24,
        score: 2
    },
    {
        name: 'Mont-De-Marsan',
        code: 40,
        score: 2
    },
    {
        name: 'Dax',
        code: 40,
        score: 2
    },
    {
        name: 'Poitiers',
        code: 86,
        score: 2
    },
    {
        name: 'Chatellerault',
        code: 86,
        score: 2
    },
    {
        name: 'Agen',
        code: 47,
        score: 2
    },
    {
        name: 'Villeneuve-Sur-Lot',
        code: 47,
        score: 2
    },
    {
        name: 'Angoulême',
        code: 16,
        score: 2
    },
    {
        name: 'Cognac',
        code: 16,
        score: 2
    },
    {
        name: 'Limoges ',
        code: 87,
        score: 2
    },
    {
        name: 'Niort',
        code: 79,
        score: 2
    },
    {
        name: 'Tarbes',
        code: 65,
        score: 2
    },
    {
        name: 'Auch',
        code: 32,
        score: 2
    },
    {
        name: 'Montauban',
        code: 82,
        score: 2
    },
    {
        name: 'Brive-La-Gaillarde ',
        code: 19,
        score: 2
    },
    {
        name: 'La Roche-Sur-Yon',
        code: 85,
        score: 2
    },
    {
        name: 'Les Sables-d\'Olonne',
        code: 85,
        score: 2
    },
    {
        name: 'Challans',
        code: 85,
        score: 2
    },
    {
        name: 'Cahors ',
        code: 46,
        score: 2
    },
    {
        name: 'Vezin-le-Coquet',
        code: 35,
        score: 2
    },
    {
        name: 'Parthenay-de-Bretagne',
        code: 35,
        score: 2
    },
    {
        name: 'Pacé',
        code: 35,
        score: 2
    },
    {
        name: 'Orgères',
        code: 35,
        score: 2
    },
    {
        name: 'Noyal-Châtillon-sur-Seiche',
        code: 35,
        score: 2
    },
    {
        name: 'Nouvoitou',
        code: 35,
        score: 2
    },
    {
        name: 'Mordelles',
        code: 35,
        score: 2
    },
    {
        name: 'Montgermont',
        code: 35,
        score: 2
    },
    {
        name: 'Miniac-sous-Bécherel',
        code: 35,
        score: 2
    },
    {
        name: 'Pont-Péan',
        code: 35,
        score: 2
    },
    {
        name: 'Rennes',
        code: 35,
        score: 2
    },
    {
        name: 'Romillé',
        code: 35,
        score: 2
    },
    {
        name: 'Vern-sur-Seiche',
        code: 35,
        score: 2
    },
    {
        name: 'Thorigné-Fouillard',
        code: 35,
        score: 2
    },
    {
        name: 'Saint-Sulpice-la-Forêt',
        code: 35,
        score: 2
    },
    {
        name: 'Saint-Jacques-de-la-Lande',
        code: 35,
        score: 2
    },
    {
        name: 'Saint-Grégoire',
        code: 35,
        score: 2
    },
    {
        name: 'Saint-Gilles',
        code: 35,
        score: 2
    },
    {
        name: 'Saint-Erblon',
        code: 35,
        score: 2
    },
    {
        name: 'Saint-Armel',
        code: 35,
        score: 2
    },
    {
        name: 'L\'Hermitage',
        code: 35,
        score: 2
    },
    {
        name: 'Le Verger',
        code: 35,
        score: 2
    },
    {
        name: 'Le Rheu',
        code: 35,
        score: 2
    },
    {
        name: 'Chartres-de-Bretagne',
        code: 35,
        score: 2
    },
    {
        name: 'Chantepie',
        code: 35,
        score: 2
    },
    {
        name: 'Cesson-Sévigné',
        code: 35,
        score: 2
    },
    {
        name: 'Bruz',
        code: 35,
        score: 2
    },
    {
        name: 'Brécé',
        code: 35,
        score: 2
    },
    {
        name: 'Bourgbarré',
        code: 35,
        score: 2
    },
    {
        name: 'Betton',
        code: 35,
        score: 2
    },
    {
        name: 'Bécherel',
        code: 35,
        score: 2
    },
    {
        name: 'Chavagne',
        code: 35,
        score: 2
    },
    {
        name: 'Chevaigné',
        code: 35,
        score: 2
    },
    {
        name: 'Cintré',
        code: 35,
        score: 2
    },
    {
        name: 'Langan',
        code: 35,
        score: 2
    },
    {
        name: 'Laillé',
        code: 35,
        score: 2
    },
    {
        name: 'La Chapelle-Thouarault',
        code: 35,
        score: 2
    },
    {
        name: 'La Chapelle-des-Fougeretz',
        code: 35,
        score: 2
    },
    {
        name: 'La Chapelle-Chaussée',
        code: 35,
        score: 2
    },
    {
        name: 'Gévezé',
        code: 35,
        score: 2
    },
    {
        name: 'Corps-Nuds',
        code: 35,
        score: 2
    },
    {
        name: 'Clayes',
        code: 35,
        score: 2
    },
    {
        name: 'Acigné',
        code: 35,
        score: 2
    },
    {
        name: 'Nantes',
        code: 44,
        score: 2
    },
    {
        name: 'Saint-Nazaire',
        code: 44,
        score: 2
    },
    {
        name: 'Saint-Herblain',
        code: 44,
        score: 2
    },
    {
        name: 'Rezé',
        code: 44,
        score: 2
    },
    {
        name: 'Saint-Sebastien-Sur-Loire',
        code: 44,
        score: 2
    },
    {
        name: 'Orvault',
        code: 44,
        score: 2
    },
    {
        name: 'Brest',
        code: 29,
        score: 2
    },
    {
        name: 'Quimper',
        code: 29,
        score: 2
    },
    {
        name: 'Lorient',
        code: 56,
        score: 2
    },
    {
        name: 'Vannes',
        code: 56,
        score: 2
    },
    {
        name: 'Lanester',
        code: 56,
        score: 2
    },
    {
        name: 'Angers',
        code: 49,
        score: 2
    },
    {
        name: 'Cholet',
        code: 49,
        score: 2
    },
    {
        name: 'Saumur',
        code: 49,
        score: 2
    },
    {
        name: 'Sèvremoine',
        code: 49,
        score: 2
    },
    {
        name: 'Caen',
        code: 14,
        score: 2
    },
    {
        name: 'Saint-Brieuc',
        code: 22,
        score: 2
    },
    {
        name: 'Lannion',
        code: 22,
        score: 2
    },
    {
        name: 'Cherbourg-en-Cotentin',
        code: 50,
        score: 2
    },
    {
        name: 'Le Mans',
        code: 72,
        score: 2
    },
    {
        name: 'Laval',
        code: 53,
        score: 2
    },
    {
        name: 'Alençon',
        code: 61,
        score: 2
    },
    {
        name: 'Tours',
        code: 37,
        score: 2
    },
    {
        name: 'Joué-lès-tours',
        code: 37,
        score: 2
    },
    {
        name: 'Art-sur-Meurthe',
        code: 54,
        score: 2
    },
    {
        name: 'Maxéville',
        code: 54,
        score: 2
    },
    {
        name: 'Nancy',
        code: 54,
        score: 2
    },
    {
        name: 'Pulnoy',
        code: 54,
        score: 2
    },
    {
        name: 'Saint-Max',
        code: 54,
        score: 2
    },
    {
        name: 'Saulxures-lès-Nancy',
        code: 54,
        score: 2
    },
    {
        name: 'Seichamps',
        code: 54,
        score: 2
    },
    {
        name: 'Tomblaine',
        code: 54,
        score: 2
    },
    {
        name: 'Vandoeuvre-lès-Nancy',
        code: 54,
        score: 2
    },
    {
        name: 'Malzéville',
        code: 54,
        score: 2
    },
    {
        name: 'Ludres',
        code: 54,
        score: 2
    },
    {
        name: 'Dommartemont',
        code: 54,
        score: 2
    },
    {
        name: 'Essey-lès-Nancy',
        code: 54,
        score: 2
    },
    {
        name: 'Fléville-devant-Nancy',
        code: 54,
        score: 2
    },
    {
        name: 'Heillecourt',
        code: 54,
        score: 2
    },
    {
        name: 'Houdemont',
        code: 54,
        score: 2
    },
    {
        name: 'Jarville-la-Malgrange',
        code: 54,
        score: 2
    },
    {
        name: 'Laneuveville-devant-Nancy',
        code: 54,
        score: 2
    },
    {
        name: 'Laxou',
        code: 54,
        score: 2
    },
    {
        name: 'Villers-lès-Nancy',
        code: 54,
        score: 2
    },
    {
        name: 'Metz',
        code: 57,
        score: 2
    },
    {
        name: 'Thionville',
        code: 57,
        score: 2
    },
    {
        name: 'Epinal',
        code: 88,
        score: 2
    },
    {
        name: 'Strasbourg',
        code: 67,
        score: 2
    },
    {
        name: 'Haguenau',
        code: 67,
        score: 2
    },
    {
        name: 'Schiltigheim',
        code: 67,
        score: 2
    },
    {
        name: 'Mulhouse',
        code: 68,
        score: 2
    },
    {
        name: 'Colmar',
        code: 68,
        score: 2
    },
    {
        name: 'Verdun',
        code: 55,
        score: 2
    },
    {
        name: 'Saint-Dizier',
        code: 52,
        score: 2
    },
    {
        name: 'Vesoul',
        code: 70,
        score: 2
    },
    {
        name: 'Troyes',
        code: 10,
        score: 2
    },
    {
        name: 'Baillargues',
        code: 34,
        score: 2
    },
    {
        name: 'Murviel-lès-Montpellier',
        code: 34,
        score: 2
    },
    {
        name: 'Pérols',
        code: 34,
        score: 2
    },
    {
        name: 'Pignan',
        code: 34,
        score: 2
    },
    {
        name: 'Prades-le-Lez',
        code: 34,
        score: 2
    },
    {
        name: 'Restinclières',
        code: 34,
        score: 2
    },
    {
        name: 'Saint-Brès',
        code: 34,
        score: 2
    },
    {
        name: 'Saint-Drézéry',
        code: 34,
        score: 2
    },
    {
        name: 'Saint-Geniès-des-Mourgues',
        code: 34,
        score: 2
    },
    {
        name: 'Saint-Georges-d\'Orques',
        code: 34,
        score: 2
    },
    {
        name: 'Saint-Jean-de-Védas',
        code: 34,
        score: 2
    },
    {
        name: 'Saussan',
        code: 34,
        score: 2
    },
    {
        name: 'Sussargues',
        code: 34,
        score: 2
    },
    {
        name: 'Vendargues',
        code: 34,
        score: 2
    },
    {
        name: 'Montpellier',
        code: 34,
        score: 2
    },
    {
        name: 'Montferrier-sur-Lez',
        code: 34,
        score: 2
    },
    {
        name: 'Montaud',
        code: 34,
        score: 2
    },
    {
        name: 'Beaulieu',
        code: 34,
        score: 2
    },
    {
        name: 'Castelnau-le-Lez',
        code: 34,
        score: 2
    },
    {
        name: 'Castries',
        code: 34,
        score: 2
    },
    {
        name: 'Clapiers',
        code: 34,
        score: 2
    },
    {
        name: 'Cournonsec',
        code: 34,
        score: 2
    },
    {
        name: 'Cournonterral',
        code: 34,
        score: 2
    },
    {
        name: 'Fabrègues',
        code: 34,
        score: 2
    },
    {
        name: 'Grabels',
        code: 34,
        score: 2
    },
    {
        name: 'Jacou',
        code: 34,
        score: 2
    },
    {
        name: 'Juvignac',
        code: 34,
        score: 2
    },
    {
        name: 'Lattes',
        code: 34,
        score: 2
    },
    {
        name: 'Lavérune',
        code: 34,
        score: 2
    },
    {
        name: 'Le Crès',
        code: 34,
        score: 2
    },
    {
        name: 'Villeneuve-lès-Maguelone',
        code: 34,
        score: 2
    },
    {
        name: 'Nimes',
        code: 30,
        score: 2
    },
    {
        name: 'Alès',
        code: 30,
        score: 2
    },
    {
        name: 'Perpignan',
        code: 66,
        score: 2
    },
    {
        name: 'Narbonne',
        code: 11,
        score: 2
    },
    {
        name: 'Carcassonne',
        code: 11,
        score: 2
    },
    {
        name: 'Albi',
        code: 81,
        score: 2
    },
    {
        name: 'Castres',
        code: 81,
        score: 2
    },
    {
        name: 'Pamiers',
        code: 9,
        score: 2
    },
    {
        name: 'Rodez',
        code: 12,
        score: 2
    },
    {
        name: 'Millau',
        code: 12,
        score: 2
    },
    {
        name: 'Mende',
        code: 48,
        score: 2
    },
    {
        name: 'Antony',
        code: 92,
        score: 2
    },
    {
        name: 'Argenteuil',
        code: 95,
        score: 2
    },
    {
        name: 'Asnières-sur-Seine',
        code: 92,
        score: 2
    },
    {
        name: 'Athis-Mons',
        code: 91,
        score: 2
    },
    {
        name: 'Aubervilliers',
        code: 93,
        score: 2
    },
    {
        name: 'Aulnay-sous-Bois',
        code: 93,
        score: 2
    },
    {
        name: 'Bagneux',
        code: 92,
        score: 2
    },
    {
        name: 'Bagnolet',
        code: 93,
        score: 2
    },
    {
        name: 'Bobigny',
        code: 93,
        score: 2
    },
    {
        name: 'Bois-Colombes',
        code: 93,
        score: 2
    },
    {
        name: 'Bondy',
        code: 93,
        score: 2
    },
    {
        name: 'Boulogne-Billancourt',
        code: 92,
        score: 2
    },
    {
        name: 'Bourg-la-Reine',
        code: 92,
        score: 2
    },
    {
        name: 'Châtenay-Malabry',
        code: 92,
        score: 2
    },
    {
        name: 'Châtillon',
        code: 92,
        score: 2
    },
    {
        name: 'Chaville',
        code: 92,
        score: 2
    },
    {
        name: 'Clamart',
        code: 92,
        score: 2
    },
    {
        name: 'Clichy',
        code: 92,
        score: 2
    },
    {
        name: 'Clichy-sous-Bois',
        code: 93,
        score: 2
    },
    {
        name: 'Colombes',
        code: 92,
        score: 2
    },
    {
        name: 'Coubron',
        code: 93,
        score: 2
    },
    {
        name: 'Courbevoie',
        code: 92,
        score: 2
    },
    {
        name: 'Drancy',
        code: 93,
        score: 2
    },
    {
        name: 'Dugny',
        code: 93,
        score: 2
    },
    {
        name: 'Épinay-sur-Seine',
        code: 93,
        score: 2
    },
    {
        name: 'Fontenay-aux-Roses',
        code: 92,
        score: 2
    },
    {
        name: 'Gagny',
        code: 93,
        score: 2
    },
    {
        name: 'Garches',
        code: 92,
        score: 2
    },
    {
        name: 'Gennevilliers',
        code: 92,
        score: 2
    },
    {
        name: 'Gournay-sur-Marne',
        code: 93,
        score: 2
    },
    {
        name: 'Issy-les-Moulineaux',
        code: 92,
        score: 2
    },
    {
        name: 'Juvisy-sur-Orge',
        code: 91,
        score: 2
    },
    {
        name: 'La Courneuve',
        code: 93,
        score: 2
    },
    {
        name: 'La Garenne-Colombes',
        code: 92,
        score: 2
    },
    {
        name: 'Le Blanc-Mesnil',
        code: 93,
        score: 2
    },
    {
        name: 'Le Bourget',
        code: 93,
        score: 2
    },
    {
        name: 'Le Plessis-Robinson',
        code: 92,
        score: 2
    },
    {
        name: 'Le Pré-Saint-Gervais',
        code: 93,
        score: 2
    },
    {
        name: 'Le Raincy',
        code: 93,
        score: 2
    },
    {
        name: 'Les Lilas',
        code: 93,
        score: 2
    },
    {
        name: 'Les Pavillons-sous-Bois',
        code: 93,
        score: 2
    },
    {
        name: 'Levallois-Perret',
        code: 92,
        score: 2
    },
    {
        name: 'L\'Île-Saint-Denis',
        code: 93,
        score: 2
    },
    {
        name: 'Livry-Gargan',
        code: 93,
        score: 2
    },
    {
        name: 'Malakoff',
        code: 92,
        score: 2
    },
    {
        name: 'Marnes-la-Coquette',
        code: 92,
        score: 2
    },
    {
        name: 'Meudon',
        code: 92,
        score: 2
    },
    {
        name: 'Montfermeil',
        code: 93,
        score: 2
    },
    {
        name: 'Montreuil',
        code: 93,
        score: 2
    },
    {
        name: 'Montrouge',
        code: 92,
        score: 2
    },
    {
        name: 'Morangis',
        code: 91,
        score: 2
    },
    {
        name: 'Nanterre',
        code: 92,
        score: 2
    },
    {
        name: 'Neuilly-Plaisance',
        code: 93,
        score: 2
    },
    {
        name: 'Neuilly-sur-Marne',
        code: 93,
        score: 2
    },
    {
        name: 'Neuilly-sur-Seine',
        code: 92,
        score: 2
    },
    {
        name: 'Noisy-le-Grand',
        code: 93,
        score: 2
    },
    {
        name: 'Noisy-le-Sec',
        code: 93,
        score: 2
    },
    {
        name: 'Pantin',
        code: 93,
        score: 2
    },
    {
        name: 'Paray-Vieille-Poste',
        code: 91,
        score: 2
    },
    {
        name: 'Pierrefitte-sur-Seine',
        code: 93,
        score: 2
    },
    {
        name: 'Puteaux',
        code: 92,
        score: 2
    },
    {
        name: 'Romainville',
        code: 93,
        score: 2
    },
    {
        name: 'Rosny-sous-Bois',
        code: 93,
        score: 2
    },
    {
        name: 'Rueil-Malmaison',
        code: 92,
        score: 2
    },
    {
        name: 'Saint-Cloud',
        code: 92,
        score: 2
    },
    {
        name: 'Saint-Denis',
        code: 93,
        score: 2
    },
    {
        name: 'Saint-Ouen-sur-Seine',
        code: 93,
        score: 2
    },
    {
        name: 'Savigny-sur-Orge',
        code: 91,
        score: 2
    },
    {
        name: 'Sceaux',
        code: 92,
        score: 2
    },
    {
        name: 'Sevran',
        code: 93,
        score: 2
    },
    {
        name: 'Sèvres',
        code: 92,
        score: 2
    },
    {
        name: 'Stains',
        code: 93,
        score: 2
    },
    {
        name: 'Suresnes',
        code: 92,
        score: 2
    },
    {
        name: 'Tremblay-en-France',
        code: 93,
        score: 2
    },
    {
        name: 'Vanves',
        code: 92,
        score: 2
    },
    {
        name: 'Vaucresson',
        code: 92,
        score: 2
    },
    {
        name: 'Vaujours',
        code: 93,
        score: 2
    },
    {
        name: 'Ville-d\'Avray',
        code: 92,
        score: 2
    },
    {
        name: 'Villemomble',
        code: 93,
        score: 2
    },
    {
        name: 'Villeneuve-la-Garenne',
        code: 92,
        score: 2
    },
    {
        name: 'Villepinte',
        code: 93,
        score: 2
    },
    {
        name: 'Villetaneuse',
        code: 93,
        score: 2
    },
    {
        name: 'Viry-Châtillon',
        code: 91,
        score: 2
    },
    {
        name: 'Versailles',
        code: 78,
        score: 2
    },
    {
        name: 'Sartrouville',
        code: 78,
        score: 2
    },
    {
        name: 'Saint-Germain-En-Laye',
        code: 78,
        score: 2
    },
    {
        name: 'Mantes-La-Jolie',
        code: 78,
        score: 2
    },
    {
        name: 'Poissy',
        code: 78,
        score: 2
    },
    {
        name: 'Conflans-Saint-Honorine',
        code: 78,
        score: 2
    },
    {
        name: 'Trappes',
        code: 78,
        score: 2
    },
    {
        name: 'Meaux',
        code: 77,
        score: 2
    },
    {
        name: 'Chelles',
        code: 77,
        score: 2
    },
    {
        name: 'Melun',
        code: 77,
        score: 2
    },
    {
        name: 'Pontault-Combault',
        code: 77,
        score: 2
    },
    {
        name: 'Savigny-Le-Temple',
        code: 77,
        score: 2
    },
    {
        name: 'Bussy-Saint-Georges',
        code: 77,
        score: 2
    },
    {
        name: 'Villeparisis',
        code: 77,
        score: 2
    },
    {
        name: 'Champs-Sur-Marne',
        code: 77,
        score: 2
    },
    {
        name: 'Roissy-En-Brie',
        code: 77,
        score: 2
    },
    {
        name: 'Torcy',
        code: 77,
        score: 2
    },
    {
        name: 'Dammarie-Les-Lys',
        code: 77,
        score: 2
    },
    {
        name: 'Combs-La-Ville',
        code: 77,
        score: 2
    },
    {
        name: 'Lagny-Sur-Marne',
        code: 77,
        score: 2
    },
    {
        name: 'Le Mée-Sur-Seine',
        code: 77,
        score: 2
    },
    {
        name: 'Montereau-Fault-Yonne',
        code: 77,
        score: 2
    },
    {
        name: 'Orléans',
        code: 45,
        score: 2
    },
    {
        name: 'Evreux',
        code: 27,
        score: 2
    },
    {
        name: 'Vernon',
        code: 27,
        score: 2
    },
    {
        name: 'Chartres',
        code: 28,
        score: 2
    },
    {
        name: 'Dreux',
        code: 28,
        score: 2
    },
    {
        name: 'Blois',
        code: 41,
        score: 2
    },
    {
        name: 'Carqueiranne',
        code: 83,
        score: 2
    },
    {
        name: 'Six-Fours-les-Plages',
        code: 83,
        score: 2
    },
    {
        name: 'Saint-Mandrier-sur-Mer',
        code: 83,
        score: 2
    },
    {
        name: 'Ollioules',
        code: 83,
        score: 2
    },
    {
        name: 'Le Revest-les-Eaux',
        code: 83,
        score: 2
    },
    {
        name: 'Le Pradet',
        code: 83,
        score: 2
    },
    {
        name: 'La Valette-du-Var',
        code: 83,
        score: 2
    },
    {
        name: 'La Seyne-sur-Mer',
        code: 83,
        score: 2
    },
    {
        name: 'La Garde',
        code: 83,
        score: 2
    },
    {
        name: 'La Crau',
        code: 83,
        score: 2
    },
    {
        name: 'Hyères',
        code: 83,
        score: 2
    },
    {
        name: 'Toulon',
        code: 83,
        score: 2
    },
    {
        name: 'Marseille',
        code: 13,
        score: 2
    },
    {
        name: 'Aix-En-Provence',
        code: 13,
        score: 2
    },
    {
        name: 'Arles',
        code: 13,
        score: 2
    },
    {
        name: 'Martigues',
        code: 13,
        score: 2
    },
    {
        name: 'Aubagne',
        code: 13,
        score: 2
    },
    {
        name: 'Nice',
        code: 6,
        score: 2
    },
    {
        name: 'Cannes',
        code: 6,
        score: 2
    },
    {
        name: 'Antibes',
        code: 6,
        score: 2
    },
    {
        name: 'Cagnes-Sur-Mer,',
        code: 6,
        score: 2
    },
    {
        name: 'Grasse',
        code: 6,
        score: 2
    },
    {
        name: 'Le Cannet',
        code: 6,
        score: 2
    },
    {
        name: 'Menton',
        code: 6,
        score: 2
    },
    {
        name: 'Saint-Laurent-Du-Var',
        code: 6,
        score: 2
    },
    {
        name: 'Vallauris',
        code: 6,
        score: 2
    },
    {
        name: 'Avignon',
        code: 84,
        score: 2
    },
    {
        name: 'Orange',
        code: 84,
        score: 2
    },
    {
        name: 'Carpentras',
        code: 84,
        score: 2
    },
    {
        name: 'Gap',
        code: 5,
        score: 2
    },
    {
        name: 'Manosque',
        code: 4,
        score: 2
    },
    {
        name: 'Aurillac',
        code: 15,
        score: 2
    },
    {
        name: 'Bourges',
        code: 18,
        score: 2
    },
    {
        name: 'Vierzon',
        code: 18,
        score: 2
    },
    {
        name: 'Châteauroux',
        code: 36,
        score: 2
    },
    {
        name: 'Issoudun',
        code: 36,
        score: 2
    },
    {
        name: 'Gueret',
        code: 23,
        score: 2
    },
    {
        name: 'Ahuy',
        code: 21,
        score: 2
    },
    {
        name: 'Magny-sur-Tille',
        code: 21,
        score: 2
    },
    {
        name: 'Marsannay-la-Côte',
        code: 21,
        score: 2
    },
    {
        name: 'Neuilly-Crimolois',
        code: 21,
        score: 2
    },
    {
        name: 'Ouges',
        code: 21,
        score: 2
    },
    {
        name: 'Perrigny-lès-Dijon',
        code: 21,
        score: 2
    },
    {
        name: 'Plombières-lès-Dijon',
        code: 21,
        score: 2
    },
    {
        name: 'Quetigny',
        code: 21,
        score: 2
    },
    {
        name: 'Saint-Apollinaire',
        code: 21,
        score: 2
    },
    {
        name: 'Sennecey-lès-Dijon',
        code: 21,
        score: 2
    },
    {
        name: 'Longvic',
        code: 21,
        score: 2
    },
    {
        name: 'Hauteville-lès-Dijon',
        code: 21,
        score: 2
    },
    {
        name: 'Fontaine-lès-Dijon',
        code: 21,
        score: 2
    },
    {
        name: 'Bressey-sur-Tille',
        code: 21,
        score: 2
    },
    {
        name: 'Bretenière',
        code: 21,
        score: 2
    },
    {
        name: 'Chenôve',
        code: 21,
        score: 2
    },
    {
        name: 'Chevigny-Saint-Sauveur',
        code: 21,
        score: 2
    },
    {
        name: 'Corcelles-les-Monts',
        code: 21,
        score: 2
    },
    {
        name: 'Daix',
        code: 21,
        score: 2
    },
    {
        name: 'Dijon',
        code: 21,
        score: 2
    },
    {
        name: 'Fénay',
        code: 21,
        score: 2
    },
    {
        name: 'Flavignerot',
        code: 21,
        score: 2
    },
    {
        name: 'Talant',
        code: 21,
        score: 2
    },
    {
        name: 'Besançon',
        code: 25,
        score: 2
    },
    {
        name: 'Montbéliard',
        code: 25,
        score: 2
    },
    {
        name: 'Auxerre',
        code: 89,
        score: 2
    },
    {
        name: 'Sens',
        code: 89,
        score: 2
    },
    {
        name: 'Nevers',
        code: 58,
        score: 2
    },
    {
        name: 'Ablon-sur-Seine',
        code: 94,
        score: 2
    },
    {
        name: 'Alfortville',
        code: 94,
        score: 2
    },
    {
        name: 'Arcueil',
        code: 94,
        score: 2
    },
    {
        name: 'Aubervilliers',
        code: 93,
        score: 2
    },
    {
        name: 'Aulnay-sous-Bois',
        code: 93,
        score: 2
    },
    {
        name: 'Bagnolet',
        code: 93,
        score: 2
    },
    {
        name: 'Bobigny',
        code: 93,
        score: 2
    },
    {
        name: 'Boissy-Saint-Léger',
        code: 94,
        score: 2
    },
    {
        name: 'Bondy',
        code: 93,
        score: 2
    },
    {
        name: 'Bonneuil-sur-Marne',
        code: 94,
        score: 2
    },
    {
        name: 'Bry-sur-Marne',
        code: 94,
        score: 2
    },
    {
        name: 'Cachan',
        code: 94,
        score: 2
    },
    {
        name: 'Champigny-sur-Marne',
        code: 94,
        score: 2
    },
    {
        name: 'Charenton-le-Pont',
        code: 94,
        score: 2
    },
    {
        name: 'Chennevières-sur-Marne',
        code: 94,
        score: 2
    },
    {
        name: 'Chevilly-Larue',
        code: 94,
        score: 2
    },
    {
        name: 'Choisy-le-Roi',
        code: 94,
        score: 2
    },
    {
        name: 'Clichy-sous-Bois',
        code: 93,
        score: 2
    },
    {
        name: 'Coubron',
        code: 93,
        score: 2
    },
    {
        name: 'Créteil',
        code: 94,
        score: 2
    },
    {
        name: 'Drancy',
        code: 93,
        score: 2
    },
    {
        name: 'Dugny',
        code: 93,
        score: 2
    },
    {
        name: 'Épinay-sur-Seine',
        code: 93,
        score: 2
    },
    {
        name: 'Fontenay-sous-Bois',
        code: 94,
        score: 2
    },
    {
        name: 'Fresnes',
        code: 94,
        score: 2
    },
    {
        name: 'Gagny',
        code: 93,
        score: 2
    },
    {
        name: 'Gentilly',
        code: 94,
        score: 2
    },
    {
        name: 'Gournay-sur-Marne',
        code: 93,
        score: 2
    },
    {
        name: 'Ivry-sur-Seine',
        code: 94,
        score: 2
    },
    {
        name: 'Joinville-le-Pont',
        code: 94,
        score: 2
    },
    {
        name: 'La Courneuve',
        code: 93,
        score: 2
    },
    {
        name: 'La Queue-en-Brie',
        code: 94,
        score: 2
    },
    {
        name: 'Le Blanc-Mesnil',
        code: 93,
        score: 2
    },
    {
        name: 'Le Bourget',
        code: 93,
        score: 2
    },
    {
        name: 'Le Kremlin-Bicêtre',
        code: 94,
        score: 2
    },
    {
        name: 'Le Perreux-sur-Marne',
        code: 94,
        score: 2
    },
    {
        name: 'Le Plessis-Trévise',
        code: 94,
        score: 2
    },
    {
        name: 'Le Pré-Saint-Gervais',
        code: 93,
        score: 2
    },
    {
        name: 'Le Raincy',
        code: 93,
        score: 2
    },
    {
        name: 'Les Lilas',
        code: 93,
        score: 2
    },
    {
        name: 'Les Pavillons-sous-Bois',
        code: 93,
        score: 2
    },
    {
        name: 'L\'Haÿ-les-Roses',
        code: 94,
        score: 2
    },
    {
        name: 'L\'Île-Saint-Denis',
        code: 93,
        score: 2
    },
    {
        name: 'Limeil-Brévannes',
        code: 94,
        score: 2
    },
    {
        name: 'Livry-Gargan',
        code: 93,
        score: 2
    },
    {
        name: 'Maisons-Alfort',
        code: 94,
        score: 2
    },
    {
        name: 'Mandres-les-Roses',
        code: 94,
        score: 2
    },
    {
        name: 'Marolles-en-Brie',
        code: 94,
        score: 2
    },
    {
        name: 'Montfermeil',
        code: 93,
        score: 2
    },
    {
        name: 'Montreuil',
        code: 93,
        score: 2
    },
    {
        name: 'Neuilly-Plaisance',
        code: 93,
        score: 2
    },
    {
        name: 'Neuilly-sur-Marne',
        code: 93,
        score: 2
    },
    {
        name: 'Nogent-sur-Marne',
        code: 94,
        score: 2
    },
    {
        name: 'Noiseau',
        code: 94,
        score: 2
    },
    {
        name: 'Noisy-le-Grand',
        code: 93,
        score: 2
    },
    {
        name: 'Noisy-le-Sec',
        code: 93,
        score: 2
    },
    {
        name: 'Orly',
        code: 94,
        score: 2
    },
    {
        name: 'Ormesson-sur-Marne',
        code: 94,
        score: 2
    },
    {
        name: 'Pantin',
        code: 93,
        score: 2
    },
    {
        name: 'Paris',
        code: 75,
        score: 2
    },
    {
        name: 'Périgny',
        code: 94,
        score: 2
    },
    {
        name: 'Pierrefitte-sur-Seine',
        code: 93,
        score: 2
    },
    {
        name: 'Romainville',
        code: 93,
        score: 2
    },
    {
        name: 'Rosny-sous-Bois',
        code: 93,
        score: 2
    },
    {
        name: 'Rungis',
        code: 94,
        score: 2
    },
    {
        name: 'Saint-Denis',
        code: 93,
        score: 2
    },
    {
        name: 'Saint-Mandé',
        code: 94,
        score: 2
    },
    {
        name: 'Saint-Maur-des-Fossés',
        code: 94,
        score: 2
    },
    {
        name: 'Saint-Maurice',
        code: 94,
        score: 2
    },
    {
        name: 'Saint-Ouen-sur-Seine',
        code: 93,
        score: 2
    },
    {
        name: 'Santeny',
        code: 94,
        score: 2
    },
    {
        name: 'Sevran',
        code: 93,
        score: 2
    },
    {
        name: 'Stains',
        code: 93,
        score: 2
    }, {
        name: 'Sucy-en-Brie',
        code: 94,
        score: 2
    },
    {
        name: 'Thiais',
        code: 94,
        score: 2
    },
    {
        name: 'Tremblay-en-France',
        code: 93,
        score: 2
    },
    {
        name: 'Valenton',
        code: 94,
        score: 2
    },
    {
        name: 'Vaujours',
        code: 93,
        score: 2
    },
    {
        name: 'Villecresnes',
        code: 94,
        score: 2
    },
    {
        name: 'Villejuif',
        code: 94,
        score: 2
    },
    {
        name: 'Villemomble',
        code: 93,
        score: 2
    },
    {
        name: 'Villeneuve-le-Roi',
        code: 94,
        score: 2
    },
    {
        name: 'Villeneuve-Saint-Georges',
        code: 94,
        score: 2
    },
    {
        name: 'Villepinte',
        code: 93,
        score: 2
    },
    {
        name: 'Villetaneuse',
        code: 93,
        score: 2
    },
    {
        name: 'Villiers-sur-Marne',
        code: 94,
        score: 2
    },
    {
        name: 'Vincennes',
        code: 94,
        score: 2
    },
    {
        name: 'Vitry-sur-Seine',
        code: 94,
        score: 2
    },
];