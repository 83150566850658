// eslint-disable-next-line
export default {
    city: {
        data: [],
        isSubmit: false
    },
    activity: {
        data: []
    },
    offers: {
        withOutEngagement: false,
        score: {
            city: null,
            activity: null
        },
        loading: 0
    }
}