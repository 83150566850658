export const offers = [
    {
        title: 'Offre N°1',
        standard: {
            title: '300€ HT/mois',
            description: 'Engagement de 12 mois avec option 6 reports de mensualités'
        },
        withOutEngagement: {
            title: '400€ HT/mois',
            description: 'Offre test de 3 mois'
        }

    },
    {
        title: 'Offre N°2',
        standard: {
            title: '425€ HT/mois',
            description: 'Engagement de 12 mois avec option 6 reports de mensualités'
        },
        withOutEngagement: {
            title: '525€ HT/mois',
            description: 'Offre test de 3 mois'
        }

    },
    {
        title: 'Offre N°3',
        standard: {
            title: '550€ HT/mois',
            description: 'Engagement de 12 mois avec option 6 reports de mensualités'
        },
        withOutEngagement: {
            title: '650€ HT/mois',
            description: 'Offre test de 3 mois'
        }

    },
    {
        title: 'Offre N°4',
        standard: {
            title: '775€ HT/mois',
            description: 'Engagement de 12 mois avec option 6 reports de mensualités'
        },
        withOutEngagement: {
            title: '875€ HT/mois',
            description: '0ffre test de 3 mois'
        }

    },
    {
        title: 'Offre N°5',
        standard: {
            title: '1000€ HT/mois',
            description: 'Engagement de 12 mois avec option 6 reports de mensualités'
        },
        withOutEngagement: {
            title: '1100€ HT/mois',
            description: 'Offre test de 3 mois'
        }

    },
    {
        title: 'Offre N°6',
        standard: {
            title: '1225 HT/mois',
            description: 'Engagement de 12 mois avec option 6 reports de mensualités'
        },
        withOutEngagement: {
            title: '1275 HT/mois',
            description: 'Frais de création 450€HT, offre test de 3 mois'
        }

    },
    {
        title: 'Offre N°7',
        standard: {
            title: '1450 HT/mois',
            description: 'Engagement de 12 mois avec option 6 reports de mensualités'
        },
        withOutEngagement: {
            title: '1500 HT/mois',
            description: 'Frais de création 450€HT, offre test de 3 mois'
        }

    },
    {
        title: 'Offre N°8',
        standard: {
            title: '1675 HT/mois',
            description: 'Engagement de 12 mois avec option 6 reports de mensualités'
        },
        withOutEngagement: {
            title: '1725 HT/mois',
            description: 'Frais de création 450€HT, offre test de 3 mois'
        }

    }
];