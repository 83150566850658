import React, {Component} from 'react';
import {Progress} from 'antd';
import {connect} from "react-redux";
import bindActionCreators from "react-redux/es/utils/bindActionCreators";
import {incrementFakeLoading} from '../../redux/action/offerActions'
// eslint-disable-next-line
import style from './loading.css'


class Loading extends Component {

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (this.props.score.city && this.props.score.activity) {
            const startFakeLoading = () => {
                setTimeout(() => {
                    this.props.incrementFakeLoading()
                }, 20)
            };
            if (this.props.loading < 100) {
                startFakeLoading()
            }
            clearTimeout(startFakeLoading)
        }
    }

    render() {
        return (
            <div className="loading-content">
                {
                    <Progress type="circle" percent={this.props.loading} />
                }
            </div>
        )
            ;
    }
}

Loading.propTypes = {};


function mapStateToProps(state) {
    return {
        score: state.offers.score,
        totalScore: state.offers.score.city + state.offers.score.activity,
        loading: state.offers.loading
    }
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators({incrementFakeLoading}, dispatch)
}

export default connect(mapStateToProps, mapDispatchToProps)(Loading);