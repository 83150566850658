import {activity} from "../constants";
import {activities} from "../../data/activities";

export const getActivitiesToInputsValues = () => async (dispatch) => {
    const CitiesDataToInputsArray = [];
    // eslint-disable-next-line
    activities.map((item, index) => {
        CitiesDataToInputsArray.push({value: item.name, score: item.score, key: index})
    });
    dispatch({type: activity.GET_ACTIVITIES_TO_INPUT_VALUE, payload: CitiesDataToInputsArray})
};

export const resetLoading = () => async (dispatch) => {

};