import React from "react";
import { PushpinOutlined } from '@ant-design/icons';

export const sortCitiesDataOptions = (data) => {
    const optionData = [];
    // eslint-disable-next-line
    data.map(item => {
        optionData.push({
            value: item.nom,
            label: (
                <div
                    style={{
                        display: 'flex',
                        justifyContent: 'space-between',
                    }}
                >
                    {item.nom}
                    <span>
        <PushpinOutlined/> {item.codesPostaux[0]}
      </span>
                </div>
            ),
            key: item.code
        })
    });
    return optionData
};