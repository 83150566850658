import {offers} from '../constants'
import {cities} from "../../data/cities";

export const switchOffer = (checked) => async (dispatch) => {
    dispatch({type: offers.OFFERS_WITHOUT_ENGAGEMENT, payload: checked});
};

export const getScoreCitySelected = (value, option) => async (dispatch) => {
    dispatch({type: offers.RESET_LOADING});
    const city = cities.find(city => city.name === value);
    if (city) {
        dispatch({type: offers.CITY_SCORE, payload: city.score});
    } else {
        dispatch({type: offers.CITY_SCORE, payload: 1});
    }
};

export const getScoreActivitySelected = (value, option) => async (dispatch) => {
    dispatch({type: offers.RESET_LOADING});
    dispatch({type: offers.ACTIVITY_SCORE, payload: option.score});
};

export const incrementFakeLoading = () => async (dispatch) => {
    dispatch({type: offers.LOADING, payload: 1});
};
