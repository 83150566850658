import initialState from './initialState';
import {city} from '../constants';

const cityReducer = (state = initialState.city, action, payload) => {
    switch (action.type) {
        case city.GET_CITIES:
            return {
                ...state,
                data: action.payload
            };
        case city.IS_SUBMIT:
            return {
                ...state,
                isSubmit: action.payload
            };
        default:
            return state;
    }
};

export default cityReducer