import axios from 'axios'
import {apis} from '../../helpers/api'
import {city} from '../constants'
import {sortCitiesDataOptions} from '../reducer/functions/sortCityData';

export const getCities = (data) => async (dispatch) => {
    if (data.length > 1 && data.length % 2 === 1) {
        dispatch({type: city.IS_SUBMIT, payload: true});
        axios.get(apis.GET_CITIES_BY_NAME + data).then(function (axiosresponce) {
            dispatch({type: city.GET_CITIES, payload: sortCitiesDataOptions(axiosresponce.data)});
            dispatch({type: city.IS_SUBMIT, payload: false});
        }).catch(function (error) {
            console.log(error);
            dispatch({type: city.IS_SUBMIT, payload: false});
        });
    }
};