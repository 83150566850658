import {configureStore} from '@reduxjs/toolkit';
import cityReducer from './reducer/cityReducer';
import offersReducer from './reducer/offersReducer';
import activityReducer from './reducer/activityReducer'

export const store = configureStore({
    reducer: {
        city: cityReducer,
        activity: activityReducer,
        offers: offersReducer
    },
});
