export const city = {
    GET_CITIES: 'GET_CITIES',
    IS_SUBMIT: 'IS_SUBMIT'
};

export const activity = {
  GET_ACTIVITIES_TO_INPUT_VALUE: 'GET_ACTIVITIES_TO_INPUT_VALUE'
};

export const offers = {
    OFFERS_WITHOUT_ENGAGEMENT: 'OFFERS_WITHOUT_ENGAGEMENT',
    CITY_SCORE: 'CITY_SCORE',
    ACTIVITY_SCORE: 'ACTIVITY_SCORE',
    LOADING: 'LOADING',
    RESET_LOADING: 'RESET_LOADING'
};