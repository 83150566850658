import React, {Component} from 'react';
import {bindActionCreators} from "redux";
import {connect} from "react-redux";
import {switchOffer} from "../../redux/action/offerActions"
import {Row, Card, Steps, Switch, Badge} from "antd";
// eslint-disable-next-line
import style from './offers.css'
import imageSea2 from "../../assets/images/sea2.png";
import {offers} from '../../data/offers';

const {Step} = Steps;
const {Meta} = Card;


const cardItem = (withOutEngagement, title, description) => {
    return (
        <Badge.Ribbon text="Offre test" className={withOutEngagement ? 'ribbon-active' : ''}>
            <Card

                hoverable
                cover={
                    <img
                        alt="example"
                        src="https://images.pexels.com/photos/39284/macbook-apple-imac-computer-39284.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=750&w=1260"
                    />
                }
            >
                <Meta title={title} description={
                    description
                }/>
            </Card>
        </Badge.Ribbon>
    )
};
const inputsDataSort = (withOutEngagement, item, type) => {
    if (type === 'title') {
        return !withOutEngagement ? item.standard.title : item.withOutEngagement.title
    }
    if (type === 'description') {
        return !withOutEngagement ? item.standard.description : item.withOutEngagement.description
    }
};

class Offers extends Component {

    render() {
        return (
            <div className="offers-content">
                <img src={imageSea2} className="logo-sea-2" alt="sea-2-vistalid"/>
                <Row className="switch-content">
                    <Switch onChange={this.props.switchOffer} checked={this.props.withOutEngagement}/>
                </Row>
                <Row>
                    <Steps
                        type="navigation"
                        current={this.props.loading === 100 ? this.props.totalScore - 1 : null}
                        className="site-navigation-steps"
                    >
                        {
                            offers.map((item, index) => {
                                return (
                                    <Step status="wait" title={item.title} key={index}
                                          description={cardItem(this.props.withOutEngagement, inputsDataSort(this.props.withOutEngagement, item, 'title'), inputsDataSort(this.props.withOutEngagement, item, 'description'))}/>
                                )
                            })
                        }
                    </Steps>
                </Row>
            </div>
        );
    }
}

Offers.propTypes = {};

function mapStateToProps(state) {
    return {
        withOutEngagement: state.offers.withOutEngagement,
        totalScore: state.offers.score.city + state.offers.score.activity,
        loading: state.offers.loading,
        score: state.offers.score
    }
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators({switchOffer}, dispatch)
}

export default connect(mapStateToProps, mapDispatchToProps)(Offers);