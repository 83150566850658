export const activities = [
    {
        name: 'Affutage',
        score: 3
    },
    {
        name: 'Agencement de combles',
        score: 3
    },
    {
        name: 'Assainissement',
        score: 3
    },
    {
        name: 'Carreleur',
        score: 3
    },
    {
        name: 'Carrière',
        score: 1
    },
    {
        name: 'Charpente',
        score: 3
    },
    {
        name: 'Chauffagiste ',
        score: 3
    },
    {
        name: 'Constructeur de terrains sportifs',
        score: 1
    },
    {
        name: 'Entreprise d’électricité générale',
        score: 2
    },
    {
        name: 'Entreprise d’étanchéité',
        score: 3
    },
    {
        name: 'Entreprise de bâtiment',
        score: 3
    },
    {
        name: 'Entreprise de couverture',
        score: 3
    },
    {
        name: 'Entreprise de dératisation',
        score: 3
    },
    {
        name: 'Entreprise de désamiantage',
        score: 2
    },
    {
        name: 'Entreprise de menuiserie',
        score: 3
    },
    {
        name: 'Entreprise de nettoyage',
        score: 2
    },
    {
        name: 'Entreprise de peinture',
        score: 3
    },
    {
        name: 'Entreprise de plâtrerie',
        score: 1
    },
    {
        name: 'Entreprise de plomberie',
        score: 3
    },
    {
        name: 'Entreprise de terrassement',
        score: 1
    },
    {
        name: 'Fenêtres',
        score: 3
    },
    {
        name: 'Ferrailleur',
        score: 1
    },
    {
        name: 'Ferronnerie',
        score: 2
    },
    {
        name: 'Fioul combustible',
        score: 3
    },
    {
        name: 'Fosses septiques',
        score: 3
    },
    {
        name: 'Installateur de cuisines',
        score: 3
    },
    {
        name: 'Installation frigorifiques',
        score: 2
    },
    {
        name: 'Installations de climatisation',
        score: 3
    },
    {
        name: 'Maçonnerie',
        score: 1
    },
    {
        name: 'Miroiterie – Vitrerie',
        score: 2
    },
    {
        name: 'Pesage industriel',
        score: 3
    },
    {
        name: 'Pisciniste',
        score: 3
    },
    {
        name: 'Plaquistes',
        score: 1
    },
    {
        name: 'Ravalement de façade',
        score: 3
    },
    {
        name: 'Scierie',
        score: 1
    },
    {
        name: 'Serrurerie',
        score: 3
    },
    {
        name: 'Stores',
        score: 3
    },
    {
        name: 'Tapissier',
        score: 1
    },
    {
        name: 'Traitement de l’habitat',
        score: 3
    },
    {
        name: 'Traitement des bois',
        score: 2
    },
    {
        name: 'Travaux publics',
        score: 1
    },
    {
        name: 'Vérandas',
        score: 3
    },
    {
        name: 'Vidange curage',
        score: 1
    },
    {
        name: 'Zinguerie',
        score: 3
    },
    {
        name: 'Accordeur',
        score: 3
    },
    {
        name: 'Accordeur de piano',
        score: 2
    },
    {
        name: 'Agent de sécurité',
        score: 1
    },
    {
        name: 'Alarmes',
        score: 3
    },
    {
        name: 'Animation artistique',
        score: 1
    },
    {
        name: 'Architecte',
        score: 1
    },
    {
        name: 'Ascenseurs',
        score: 1
    },
    {
        name: 'Association',
        score: 1
    },
    {
        name: 'Couturière',
        score: 1
    },
    {
        name: 'Deblaiement',
        score: 3
    },
    {
        name: 'Demenagement',
        score: 3
    },
    {
        name: 'Fabrication',
        score: 3
    },
    {
        name: 'Informatique',
        score: 1
    },
    {
        name: 'Installateur d\'antennes de television',
        score: 1
    },
    {
        name: 'Installation d\'expositions',
        score: 1
    },
    {
        name: 'Photographe',
        score: 1
    },
    {
        name: 'Pompes Funèbres',
        score: 1
    },
    {
        name: 'Pressing',
        score: 1
    },
    {
        name: 'Recyclage',
        score: 1
    },
    {
        name: 'Reparateur bateaux',
        score: 1
    },
    {
        name: 'Reparateur electromenager',
        score: 1
    },
    {
        name: 'Voyante',
        score: 2
    },
    {
        name: 'Enseignes',
        score: 1
    },
    {
        name: 'Imprimerie',
        score: 1
    },
    {
        name: 'Panneaux publicitaires',
        score: 3
    },
    {
        name: 'Signalétique intérieure',
        score: 3
    },
    {
        name: 'Agence immobilière',
        score: 1
    },
    {
        name: 'Constructeur de maison individuelle',
        score: 2
    },
    {
        name: 'Promoteur immobilier',
        score: 2
    },
    {
        name: 'Rénovation immobilière',
        score: 1
    },
    {
        name: 'Assureur',
        score: 2
    },
    {
        name: 'Inventaire',
        score: 1
    },
    {
        name: 'Audits',
        score: 1
    },
    {
        name: 'Avocat',
        score: 1
    },
    {
        name: 'Comptable',
        score: 2
    },
    {
        name: 'Courtier',
        score: 2
    },
    {
        name: 'Détective',
        score: 1
    },
    {
        name: 'Formation',
        score: 2
    },
    {
        name: 'Ingénierie',
        score: 1
    },
    {
        name: 'Notaire',
        score: 1
    },
    {
        name: 'Ebénisterie',
        score: 1
    },
    {
        name: 'Elagage',
        score: 3
    },
    {
        name: 'Exploitation forestière',
        score: 1
    },
    {
        name: 'Fleuriste',
        score: 1
    },
    {
        name: 'Géologie',
        score: 1
    },
    {
        name: 'Jardinerie',
        score: 1
    },
    {
        name: 'Marbrerie',
        score: 1
    },
    {
        name: 'Paysagistes',
        score: 1
    },
    {
        name: 'Pépiniériste',
        score: 1
    },
    {
        name: 'Travaux agricoles',
        score: 1
    },
    {
        name: 'Ambulances',
        score: 1
    },
    {
        name: 'Hypnothérapeute',
        score: 2
    },
    {
        name: 'Infirmière',
        score: 1
    },
    {
        name: 'Magnétiseur ',
        score: 2
    },
    {
        name: 'Médecins ',
        score: 1
    },
    {
        name: 'Opticien',
        score: 1
    },
    {
        name: 'Ostéopathe',
        score: 1
    },
    {
        name: 'Pharmacie',
        score: 1
    },
    {
        name: 'Podologue',
        score: 1
    },
    {
        name: 'Psychanalyste',
        score: 1
    },
    {
        name: 'Psychologue',
        score: 1
    },
    {
        name: 'Psychothérapeute',
        score: 1
    },
    {
        name: 'Radiologie',
        score: 1
    },
    {
        name: 'Résidence personnes âgées',
        score: 2
    },
    {
        name: 'Sophrologue',
        score: 1
    },
    {
        name: 'Accessoires de cheminées',
        score: 3
    },
    {
        name: 'Antiquités',
        score: 1
    },
    {
        name: 'Bijouterie',
        score: 1
    },
    {
        name: 'Brocante',
        score: 3
    },
    {
        name: 'Fournisseur aluminium',
        score: 1
    },
    {
        name: 'Fournisseur équipements industriels',
        score: 1
    },
    {
        name: 'Fournisseurs équipements solaires',
        score: 2
    },
    {
        name: 'Location de bennes',
        score: 3
    },
    {
        name: 'Location produits',
        score: 1
    },
    {
        name: 'Magasin',
        score: 3
    },
    {
        name: 'Poêles',
        score: 3
    },
    {
        name: 'Poêles granulés inserts',
        score: 3
    },
    {
        name: 'Ventes',
        score: 1
    },
    {
        name: 'Coiffeur',
        score: 1
    },
    {
        name: 'Cours de danse',
        score: 1
    },
    {
        name: 'Institut de beauté',
        score: 1
    },
    {
        name: 'Relaxation',
        score: 2
    },
    {
        name: 'Sport',
        score: 1
    },
    {
        name: 'Centre équestre',
        score: 1
    },
    {
        name: 'Chenil',
        score: 3
    },
    {
        name: 'Elevage',
        score: 1
    },
    {
        name: 'Elevage chiens chats',
        score: 1
    },
    {
        name: 'Toilettage',
        score: 1
    },
    {
        name: 'Auto-école',
        score: 2
    },
    {
        name: 'Transport',
        score: 1
    },
    {
        name: 'Taxis',
        score: 3
    },
    {
        name: 'Dépannage transport',
        score: 2
    },
    {
        name: 'Location automobile',
        score: 2
    },
    {
        name: 'Carrosserie automobile',
        score: 1
    },
    {
        name: 'Garage automobile',
        score: 1
    },
    {
        name: 'Boucherie',
        score: 1
    },
    {
        name: 'Boulangerie',
        score: 1
    },
    {
        name: 'Charcuterie',
        score: 1
    },
    {
        name: 'Restaurants',
        score: 1
    },
    {
        name: 'Ostréiculture',
        score: 1
    },
    {
        name: 'Gites',
        score: 1
    },
    {
        name: 'Hôtel',
        score: 3
    },
    {
        name: 'Camping',
        score: 3
    }
];